import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Education from "./components/Education";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/components" element={<Education />} />
          <Route path="/components" element={<Projects />} />
          <Route path="/components" element={<Skills />} />
          <Route path="/components" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
