import React from "react";
import Hello from "../components/Hello";
import Education from "../components/Education";
import Experience from "../components/Experience";
import Projects from "../components/Projects";
import Skills from "../components/Skills";
import Contact from "../components/Contact";

function Home() {
  return (
    <>
      <Hello />
      <Education />
      <Experience />
      <Projects />
      <Skills />
      <Contact />
    </>
  );
}

export default Home;
