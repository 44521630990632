import AboutImg from "../pics/UCDavis.png";
import "../styles/Education.css";

function Education() {
  const hash = window.location.hash.substring(1);
  const graduationDate = "June 2024";
  return (
    <>
      <section className="about" id="education">
        <div className="about-content">
          <div className="img-side">
            <img src={AboutImg} alt="me" className="img-side__main-img" />
          </div>
          <div className="text-side">
            <h3>My Education</h3>
            <h4>
              Bachelor of Science in Computer Science
              <br /> University of California, Davis
            </h4>
            <p>
              Specialize in <strong>Information</strong> <br />
              Graduated {graduationDate}
              <br /><br />
              <p><strong>About me</strong></p>
              I started programming during Covid-19 and turned it into a hobby.
              I spend my day studying for school and working on web applications.
              Besides coding, I love creating new things, listening to music,
              and spending time with family & friends.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Education;
