import "../styles/Skills.css";
import JavaScriptLogo from "../logos/javascript.png";
import ReactLogo from "../logos/react.png";
import HTMLCSSLogo from "../logos/htmlcss.png";
import BootstrapLogo from "../logos/bootstrap.png";
import CppLogo from "../logos/C++.png";
import CLogo from "../logos/C.png";
import GanacheLogo from "../logos/ganache.png";
import GitLogo from "../logos/git.png";
import JavaLogo from "../logos/java.png";
import MySQLLogo from "../logos/mysql.png";
import PythonLogo from "../logos/python.png";
import ResilientDBLogo from "../logos/resilientdb.png";
import SolidityLogo from "../logos/solidity.png";
import SQLiteLogo from "../logos/sqlite.png";
import Web3jsLogo from "../logos/web3js.png";
import MetamaskLogo from "../logos/metamask.png";
import MongoDBLogo from "../logos/mongo.png";
import NodejsLogo from "../logos/nodejs.png";
import UbuntuLogo from "../logos/ubuntu.png";

const skills = [
  { name: 'JavaScript', logo: JavaScriptLogo },
  { name: 'React', logo: ReactLogo },
  { name: 'HTML/CSS', logo: HTMLCSSLogo },
  { name: 'Python', logo: PythonLogo },
  { name: 'C/C++', logo: CppLogo },
  { name: 'Java', logo: JavaLogo },
  { name: 'Solidity', logo: SolidityLogo },
  { name: 'Git', logo: GitLogo },
  { name: 'Bootstrap', logo: BootstrapLogo },
  { name: 'Node.js', logo: NodejsLogo },
  { name: 'Web3.js', logo: Web3jsLogo },
  { name: 'ResilientDB', logo: ResilientDBLogo },
  { name: 'SQLite', logo: SQLiteLogo },
  { name: 'MySQL', logo: MySQLLogo },
  { name: 'MongoDB', logo: MongoDBLogo },
  { name: 'Ubuntu', logo: UbuntuLogo },
  { name: 'Ganache', logo: GanacheLogo },
  { name: 'Metamask', logo: MetamaskLogo },
];

const Skills = () => {
  return (
    <section className="skills-container" id="skills">
      <h3>My Skills</h3>
      <div className="skills-grid">
        {skills.map((skill) => (
          <div key={skill.name} className="skill">
            <img src={skill.logo} alt={skill.name} className="skill-logo" />
            <p className="skill-name">{skill.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
