import { IconBrandGithub, IconBrandLinkedin } from "@tabler/icons-react";
import "../styles/Hello.css";

function Hello() {
  const hash = window.location.hash.substring(1);
  const resumeLink = "https://drive.google.com/file/d/1Owy9JwKe5yqWbeRJxKvoI_O9uxbHgNBs/view?usp=sharing";
  return (
    <>
      <section id="home" className="hello">
        {/* <div className="container"> */}
        <div className="content">
          <div className="hello-main">
            <div className="hello-text">
              <h1>
                Xin chào <br /> I'm Anh Huynh
              </h1>
              <p>
                A passionate Software Developer graduated from <br />
                University of California, Davis. 🐮
              </p>
              <span>
                <a
                  aria-label="linkedin"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/annieh195/"
                >
                  <IconBrandLinkedin width={32} height={32} />
                </a>
                <a
                  aria-label="github"
                  rel="noreferrer"
                  target="_blank"
                  href="https://github.com/annieh195"
                >
                  <IconBrandGithub width={32} height={32} />
                </a>
              </span>
              <button type="submit" onClick={() => window.open(resumeLink)}>
                My Resume
              </button>
            </div>
            <div className="hello-img"></div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default Hello;