import { Icon123, IconBrandGithub, IconBrandYoutube, IconHeart } from "@tabler/icons-react";
import "../styles/Projects.css";
import JavaScriptLogo from "../logos/javascript.png";
import ReactLogo from "../logos/react.png";
import HTMLCSSLogo from "../logos/htmlcss.png";
import BootstrapLogo from "../logos/bootstrap.png";
import CppLogo from "../logos/C++.png";
import CLogo from "../logos/C.png";
import GanacheLogo from "../logos/ganache.png";
import GitLogo from "../logos/git.png";
import JavaLogo from "../logos/java.png";
import MySQLLogo from "../logos/mysql.png";
import PythonLogo from "../logos/python.png";
import ResilientDBLogo from "../logos/resilientdb.png";
import SolidityLogo from "../logos/solidity.png";
import SQLiteLogo from "../logos/sqlite.png";
import Web3jsLogo from "../logos/web3js.png";
import MetamaskLogo from "../logos/metamask.png";
import MongoDBLogo from "../logos/mongo.png";
import NodejsLogo from "../logos/nodejs.png";
import D3jsLogo from "../logos/d3js.png";
import UbuntuLogo from "../logos/ubuntu.png";
import GoogleAPILogo from "../logos/ggapi.png";
import SetlistAPILogo from "../logos/setlistapi.png";
import SocketIOLogo from "../logos/socket.png";
import PostgreSQLLogo from "../logos/PostgreSQL.png";
import ReduxLogo from "../logos/redux.png";
import React, { useState, useEffect, useRef } from 'react';

function Projects() {
  const [currentBox, setCurrentBox] = useState(null);
  const projectRefs = useRef([]);

  const handleScroll = () => {
    const boxes = projectRefs.current;
    const viewportHeight = window.innerHeight;
    let nearestBoxIndex = -1;
    let minDistanceToViewport = viewportHeight;

    boxes.forEach((box, index) => {
      if (box) {
        const boxRect = box.getBoundingClientRect();
        const boxTopDistanceToViewport = Math.abs(boxRect.top);

        if (boxTopDistanceToViewport - 70 < minDistanceToViewport + 200) {
          minDistanceToViewport = boxTopDistanceToViewport;
          nearestBoxIndex = index;
        }
      }
    });

    setCurrentBox(nearestBoxIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="projects" className="projects">
      <h3>My Projects</h3>

      {/* Charity Project */}
      <div className={`bullet ${currentBox === 0 ? 'current' : ''}`} ref={el => projectRefs.current[0] = el}>
        <h4>Charity Blockchain System 1.0
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/annieh195/Charity-Blockchain-System"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
        </h4>
        <p>
          <IconHeart className="icon-heart" /> Led a team of 6 members in developing a charity donation website with efficient transaction handling.
          <br /><IconHeart className="icon-heart" /> Implemented Smart Contracts using Solidity for features like fund creation, and donation processing.
          <br /><IconHeart className="icon-heart" /> Developed a real-time donation tracking system, displaying user addresses with donation amounts for transparent and accountable monitoring of all transactions.
          <br /><IconHeart className="icon-heart" /> Utilized ReactJS and Web3.js for seamless interaction with Ethereum blockchain.
          <br /><IconHeart className="icon-heart" /> Deployed on personal blockchain Ganache using Truffle, and integrated Ethereum provider MetaMask.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={ResilientDBLogo} />
          <img src={SolidityLogo} />
          <img src={ReactLogo} />
          <img src={Web3jsLogo} />
          <img src={GanacheLogo} />
          <img src={MetamaskLogo} />
          <img src={UbuntuLogo} />
        </div>
      </div>

      {/* Data Vis Project */}
      <div className={`bullet ${currentBox === 1 ? 'current' : ''}`} ref={el => projectRefs.current[1] = el}>
        <h4>Kpop Data Visualizations
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/annieh195/Kpop-Data-Visualizations"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
          <a
            aria-label="youtube"
            rel="noreferrer"
            target="_blank"
            href="https://www.youtube.com/watch?v=n2C2sBf4upY&ab_channel=JamieWu"
          >
            <IconBrandYoutube width={32} height={32} />
          </a>
        </h4>
        <p>
          <IconHeart className="icon-heart" /> Led a 3-member team in developing a user-friendly web-based data visualization tool using D3.js and
          HTML/CSS to interactively analyze and present K-Pop trends and their impact in the U.S.
          <br /><IconHeart className="icon-heart" /> Managed complex data sourcing and formatting by integrating Google Trends API and Setlist.fm API for
          comprehensive K-Pop music group analysis.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={D3jsLogo} />
          <img src={GoogleAPILogo} />
          <img src={SetlistAPILogo} />
          <img src={HTMLCSSLogo} />
        </div>
      </div>

      {/* Work Shift Project */}
      <div className={`bullet ${currentBox === 2 ? 'current' : ''}`} ref={el => projectRefs.current[2] = el}>
        <h4>Work Shift Management Application
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/lasksafj/Work-Flow-Sync"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
        </h4>
        <p>
          <IconHeart className="icon-heart" /> Participated in a 6-member team developing a work shift management application designed to streamline
          scheduling, communication, and task management for service-oriented businesses.
          <br /><IconHeart className="icon-heart" /> Built an earnings feature in React, enabling users to calculate weekly earnings accurately and efficiently with dynamic updates, real-time input validation, and seamless integration with back-end APIs.
          <br /><IconHeart className="icon-heart" /> Managed API development with RESTful services and managed state using Redux.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={ReactLogo} />
          <img src={NodejsLogo} />
          <img src={SocketIOLogo} />
          <img src={PostgreSQLLogo} />
          <img src={ReduxLogo} />
        </div>
      </div>

      {/* Operating Systems */}
      <div className={`bullet ${currentBox === 3 ? 'current' : ''}`} ref={el => projectRefs.current[3] = el}>
        <h4>Operating System Projects</h4>
        <p>
          <IconHeart className="icon-heart" /> <strong>User-level Thread Library:</strong> Implemented a libraby for Linux, featuring thread creation, round-robin scheduling,
          preemptive interrupt-based scheduling, and semaphore-based synchronization.
          <br /><IconHeart className="icon-heart" /> <strong>Shell:</strong> Developed a command-line interpreter, capable of executing UNIX commands with multiple arguments,
          handling typical built-in commands, redirecting standard output to files, and composing commands via piping.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={CLogo} />
        </div>
      </div>

      {/* Tic-Tac-Toe Project */}
      <div className={`bullet ${currentBox === 4 ? 'current' : ''}`} ref={el => projectRefs.current[4] = el}>
        <h4>Tic-Tac-Toe A.I.
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/annieh195/Tic-Tac-Toe-AI-MCTS"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
        </h4>
        <p>
          <IconHeart className="icon-heart" /> Developed a Python Tic-Tac-Toe game featuring an advanced AI opponent implemented with the Monte
          Carlo Tree Search simulation method.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={PythonLogo} />
        </div>
      </div>

      {/* N-Queens Project */}
      <div className={`bullet ${currentBox === 5 ? 'current' : ''}`} ref={el => projectRefs.current[5] = el}>
        <h4>N-Queens Problem
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/annieh195/N-Queens-Problem"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
        </h4>
        <p><IconHeart className="icon-heart" /> Implement the N-Queens Problem using Constrained Satisfactory Problem (CSP) Backtracking approach in Python on the 2-D array.</p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={PythonLogo} />
        </div>
      </div>

      {/* Bookstore Project */}
      <div className={`bullet ${currentBox === 6 ? 'current' : ''}`} ref={el => projectRefs.current[6] = el}>
        <h4>Bookstore
          <a
            aria-label="github"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/lasksafj/Bookstore"
          >
            <IconBrandGithub width={32} height={32} />
          </a>
        </h4>
        <p>
          <IconHeart className="icon-heart" /> Full-stack bookstore management application in Python with Tkinter, enabling book searching, selling, and tracking of customers, employees, and sales.
          <br /><IconHeart className="icon-heart" /> Implemented robust client-side features for managing books, orders, customers, and human resources.
          <br /><IconHeart className="icon-heart" /> Designed an SQLite database with one-to-many relational models to support efficient data handling and storage.
        </p>
        <div className="made-with">
          <h5>Made with:</h5>
          <img src={PythonLogo} />
        </div>
      </div>
    </div>
  )
}

export default Projects