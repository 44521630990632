import "../styles/Experience.css";

function Experience() {
  var link = <a href="https://resilientdb.incubator.apache.org/" style={{ textDecoration: "none" }}>Apache ResilientDB</a>;
  return (
    <section className="experience" id="experience">
      <h3>My Experience</h3>
      <h4><strong>Software Developer</strong>
        <br />Expo Lab - UC Davis
        <br /> <i>PI: Prof. Mohammad Sadoghi</i>
      </h4>
      <p>
        •	Led a <strong>6-member</strong> team in the design & development
        of a decentralized application <strong>Charity Blockchain System 3.0
        </strong>, utilizing <strong>ReactJs</strong> for the
        front-end, and replacing <strong> Solidity</strong> and <strong> Web3.js
        </strong> with <strong> ExpressJS</strong> for back-end operations and
        <strong> GraphQL</strong> for data query optimization. This streamlined
        operations, enhanced data query efficiency, and ensured robust transaction
        processing.
        <br />
        <br />•	Transitioned from local Ganache deployment to a research-grade
        distributed ledger (<strong>{link}</strong>), significantly improving
        scalability and reliability.
        <br />
        <br />•	Leveraged <strong>{link}</strong> research, a high-throughput
        distributed ledger technology, to serve as the database system for
        ensuring reliable and scalable transactions management in a
        decentralized network.
        <br />
        <br />•	Engineered a real-time donation tracking feature that
        enhanced transparency by displaying donor addresses and contribution
        amounts.
      </p>
    </section >
  );
}

export default Experience;