import { IconBrandGithub, IconBrandLinkedin, IconBrandGmail } from "@tabler/icons-react";
import "../styles/Contact.css";

function Contact() {
  return (
    <section className="contact" id="contact">
      <h3>Contact</h3>
      <p>Let's connect with me 🤝</p>
      <span>
        <a
          aria-label="linkedin"
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/annieh195/"
        >
          <IconBrandLinkedin width={32} height={32} />
        </a>
        <a
          aria-label="github"
          rel="noreferrer"
          target="_blank"
          href="https://github.com/annieh195"
        >
          <IconBrandGithub width={32} height={32} />
        </a>
        <a
          aria-label="email"
          rel="noreferrer"
          target="_blank"
          href="mailto: ahuynh1905@gmail.com"
        >
          <IconBrandGmail width={32} height={32} />
        </a>
      </span>
      <p>
        Designed by <strong>Anh Huynh @ 2023</strong>
      </p>
    </section >
  );
}

export default Contact;
